<template>
  <div class="problem-detail">
    <el-table :data="tableData" border>
      <el-table-column prop="user_name" label="用户名"> </el-table-column>
      <el-table-column prop="problem_type" label="反馈问题类型">
      </el-table-column>
      <el-table-column prop="pic_list" label="图片">
        <template slot-scope="scope">
          <div v-if="!scope.row.pic_list.length">无图片</div>
          <img
            v-if="scope.row.pic_list.length"
            v-for="(item, index) in scope.row.pic_list"
            :src="item"
            style="width: 60px; height: 60px"
          />
        </template>
      </el-table-column>
      <el-table-column prop="problem_desc" label="问题描述"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间"
        ><template slot-scope="scope"
          ><span>{{ format_time(scope.row.create_time) }}</span></template
        >
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
      background
    >
    </el-pagination>
  </div>
</template>

<script type="text/babel">
import moment from "moment";
export default {
  data() {
    return {
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      total: 100,
    };
  },

  mounted() {
    this.get_feedback_list(this.pageSize, this.currentPage);
  },

  methods: {
    handleSearch() {
      this.currentPage = 1; // 重置当前页码
      // this.fetchData(); // 获取数据
    },
    handleSizeChange(val) {
      this.pageSize = val; // 改变每页显示的数量
      this.currentPage = 1; // 重置当前页码
      // this.fetchData(); // 获取数据
    },
    handleCurrentChange(val) {
      this.currentPage = val; // 改变当前页码
      // console.log("当前页面： ", this.currentPage);
      this.get_feedback_list(this.pageSize, this.currentPage); // 获取数据
    },

    async get_feedback_list(page_size, page_num) {
      // console.log("执行createdhanshu la");
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/feed_detail",
          {
            headers: { "Content-Type": "application/json" },
            page_num: page_num,
            page_size: page_size,
          }
        );

        // console.log("执行结构： ", response.data);
        // console.log("length: ", response.data);
        if (response.data.main.length == 0) {
          this.message_box("error", "该用户还没有在本站注册，请先注册");
        } else {
          // console.log("接口返回结果response: ", response);
          // console.log(response.data);
          // this.$router.replace({ path: "/" });
          // this.close_login_box(true);
          // for (let i = 0; i < response.data.length; i++) {
          //   let row_ob = response.data[i];
          //   const user_name = row_ob.user_name;
          //   const problem_type = row_ob.problem_type;
          //   const problem_desc = row_ob.problem_desc;
          //   const pic_list = row_ob.pic_list;
          //   const create_time = row_ob.create_time;
          //   for()
          // }
          const userInfoJson = response.data.main;

          // this.message_box("success", "登录成功");
          this.tableData = userInfoJson;
          this.total = response.data.total;

          // console.log("执行结果： 22222222 ", this.tableData, this.total);
          // 在这里编写需要延迟执行的代码
          // this.change_login_status(true);

          return;
        }

        //   console.log(response);
      } catch (e) {
        console.log(e);
      }
    },

    format_time(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.el-table {
}
</style>
